import React, { useEffect, useState } from 'react'
import { questionService } from '~services'
import Answer from './components/Answer'
import Question from './components/Question'

export default function QuestionContainer() {
    const [question, setQuestion] = useState({})

    useEffect(() => {
        const subscription = questionService.onSet()
            .subscribe(content => {
                setQuestion(content)
                console.log(content)
            })

        return () => {
            subscription.unsubscribe();
        }
    }, [])

    return (
        Object.keys(question).length > 0 ?
            <Question content={question.content}>
                {question.answers.map((item, index) => (
                    <Answer key={`answer_${index}`} content={item.content} />
                ))}
            </Question>
            :
            null
    )
}
