import React, { useMemo } from 'react'
import Light from './Light'
import { VolumetricSpotLightMaterial } from '../../../helpers/three.helper';

const all_reflectors = [
    { position: [36.5 / 5, 1.2 / 5, -8.6 / 5], rotation: [-0.65, 0, 0.65], target: [3.31, 4.02, -3.74] },
    { position: [14.5 / 5, 1.2 / 5, -20.7 / 5], rotation: [0.65, 0, -0.65], target: [6.84, 4.03, -1.37] },
    { position: [23.5 / 5, 0.65 / 5, -42 / 5], rotation: [0.58, 0, -0.58], target: [8.15, 4, -6.08] },
    { position: [32.5 / 5, 0.65 / 5, -42.4 / 5], rotation: [0.58, 0, 0.58], target: [5.01, 4.03, -10.01] }

]


const Reflectors = () => {
    const material = useMemo(() => VolumetricSpotLightMaterial(), [])
    material.uniforms.lightColor.value.set('white')


    return all_reflectors.map((item, index) => (
        <Light {...item} key={"light_" + index} basicMaterial={material} />
    ))

}

//export default Reflectors
export default React.memo(Reflectors)


