export const SET_LOOKAT = 'SET_LOOKAT';
export const SET_POSITION = 'SET_POSITION';
export const TOGGLE_CAMERA = 'TOGGLE_CAMERA';

export function setLookAt(val) {
    return {
        type: SET_LOOKAT,
        val: val
    };
}

export function setPosition(val) {
    return {
        type: SET_POSITION,
        val: val
    };
}

export function toggleCamera(val) {
    return {
        type: TOGGLE_CAMERA,
        val: val
    };
}
