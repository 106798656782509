import React from 'react'

export default function Question({ children, content }) {
    return (
        <div className="question-container">
            <div className="question-content">
                <div className="background"></div>
                <h1>{content}</h1>
            </div>
            <div className="answers-container">
                {children}
            </div>
        </div>
    )
}
