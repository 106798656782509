import { SET_ANIMATION } from '../actions/animationAction';
const initialState = { currentAnimation: undefined };

export default function animationReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ANIMATION:
            return { ...state, currentAnimation: action.val };
        default:
            return state;
    }
}
