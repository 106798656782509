import React from 'react'
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Wrapper from '../Scene/Wrapper';
import Login from '../Login'
import { Alert } from '../Alert';
import Question from '../Question'

const Router = () => {
    return (
        <BrowserRouter>
            <Alert />
            <Question/>
            <Switch>
                <Route exact path="/:id" component={Wrapper} />
                <Route exact path="/" component={Login} />
                <Redirect from="*" to="/" />
            </Switch>
        </BrowserRouter>
    )
}


export default Router;
