import { combineReducers } from 'redux'

import fileReducer from './reducers/fileReducer'
import mouseReducer from './reducers/mouseReducer'
import cameraReducer from './reducers/cameraReducer'
import animationReducer from './reducers/animationReducer'
import loaderReducer from './reducers/loaderReducer'
import layerReducer from './reducers/layerReducer'

const rootReducer = combineReducers({
  file: fileReducer,
  followMouse:mouseReducer,
  camera:cameraReducer,
  animation:animationReducer,
  loader:loaderReducer,
  layer:layerReducer
})

export default rootReducer
