import React, { Suspense, useEffect } from 'react'
import { extend, useThree } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import StudioLoader from '~loaders/StudioLoader'
import { Stats } from '@react-three/drei'
import { useCameraMoves } from '../Hooks/EaseCamera'
import { useIntersect } from '../Hooks/Intersect'
import { connect, useDispatch } from 'react-redux'
import Reflectors from './components/Reflectors'
import HostLoader from '~loaders/HostLoader'
import PlayersView from '../PlayersView'
import { Vector3 } from 'three'
import { toggleCamera } from '../../redux/actions/cameraAction'
import { toggleLayer } from '../../redux/actions/layerAction'
import { layersLoaded } from '../../redux/actions/loaderAction'

extend({ OrbitControls })

const camera_positions = [
    [{ x: 17.5 / 5, y: 3.75 / 5, z: -27.5 / 5 }, { x: 25 / 5, y: 3.5 / 5, z: -30 / 5 }], // WHOLE STUDIO
    [{ x: 27.25 / 5, y: 3.5 / 5, z: -34 / 5 }, { x: 25 / 5, y: 3 / 5, z: -27.5 / 5 }], // FRONT FACE OF HOST
    [{ x: 22.5 / 5, y: 4 / 5, z: -22.5 / 5 }, { x: 25 / 5, y: 3.5 / 5, z: -30 / 5 }], // BACK OF HOST
    [{ x: 27.25 / 5, y: 6 / 5, z: -40 / 5 }, { x: 25 / 5, y: 3 / 5, z: -27.5 / 5 }], // FRONT FACE OF HOST BUT UPPER
    [{ x: 4.8, y: 9 / 5, z: -2.9 }, { x: 4.18, y: 9 / 5, z: -2.3 }], // TV
]

const mapStateToProps = (state) => {
    return {
        file: state.file,
        lookAt: state.camera.lookAt,
        position: state.camera.position,
        current_camera: state.camera.currentCamera,
        loaded: state.loader.counter === 4,
        layer: state.layer,
        layers_loaded: state.loader.layersLoaded
    }
}


const Scene = ({ layer, lookAt, position, current_camera, loaded, layers_loaded }) => {
    const [setTarget] = useCameraMoves()
    const [turnOnRaycaster] = useIntersect();
    const dispatch = useDispatch()

    const {
        camera,
        scene,
        gl: { domElement }
    } = useThree()

    if (!layers_loaded) {
        scene.onAfterRender = () => {

            if (camera.layers.mask === 1) {
                if (scene.getObjectByName("studio")) {
                    dispatch(toggleLayer(1))
                }
            }
            else if (camera.layers.mask === 2) {
                if (scene.getObjectByName("player_1")) {
                    scene.onAfterRender = () => { }
                    dispatch(toggleLayer(0))
                }

            }
        }
    }




    useEffect(() => {
        camera.position.set(position.x, position.y, position.z)
        camera.lookAt(lookAt)
        scene.background = "black"
        //turnOnRaycaster();
    }, [])

    useEffect(() => {
        if (layer !== null) {
            camera.layers.disable(layer === 0 ? 1 : 0)
            camera.layers.enable(layer);

            if (layer === 1) {
                camera.position.set(0.4, 0.5, -0.35)
                camera.lookAt(new Vector3(0.1,0.5,0))
            }
            else if (layer === 0) {
                camera.position.set(27.25 / 5, 3.5 / 5, -34 / 5)
                camera.lookAt(new Vector3(25 / 5, 3 / 5, -27.5 / 5))
                dispatch(toggleCamera(1))

                if (!layers_loaded)
                    dispatch(layersLoaded())
            }
        }
    }, [layer])

    useEffect(() => {
        const easeCallback = () => {
            if (current_camera === 4) {
                dispatch(toggleLayer(1))
            }
        }

        if (current_camera !== undefined && camera_positions[current_camera]) {
            setTarget(camera_positions[current_camera][0], camera_positions[current_camera][1], easeCallback)
        }
    }, [current_camera])


    return (
        <>
            <Stats
                showPanel={0} // Start-up panel (default=0)
                className="stats" // Optional className to add to the stats container dom element
            />

            <orbitControls args={[camera, domElement]} />


            <ambientLight intensity={1} layers={0} />
            <ambientLight intensity={1} layers={1} />

            <Suspense fallback="Trwa ładowanie">
                <StudioLoader loaded={loaded} />
            </Suspense>

            <Suspense fallback="Trwa ładowanie">
                <HostLoader loaded={loaded} />
            </Suspense>


            <PlayersView />
            {loaded && <Reflectors />}
        </>
    )
}

export default connect(mapStateToProps)(Scene)