import {
    SET_FILE, SET_ANIMS
} from '../actions/fileAction';

const initialState = "";

export default function fileReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FILE:
            return action.val;
        case SET_ANIMS:
            return { animations: action.val, ...state };
        default:
            return state;
    }
}
