import { Subject } from 'rxjs';

const questionSubject = new Subject();


const onSet = () => {
    return questionSubject.asObservable();
}

const set = (content = {}) => {
    questionSubject.next(content);
}

export const questionService = {
    onSet,
    set
};
