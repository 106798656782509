import { useThree, useFrame } from '@react-three/fiber'
import * as TWEEN from '@tweenjs/tween.js'
import store from '../App/store'
import { setLookAt, setPosition } from '../../redux/actions/cameraAction'


export const useCameraMoves = () => {
    const easing_style = TWEEN.Easing.Quadratic.InOut
    const { camera } = useThree()
    const animatePosition = (vector, callback) => {
        const { camera: redux_camera } = store.getState()
        const start = { x: redux_camera.position.x, y: redux_camera.position.y, z: redux_camera.position.z }

        const animation = new TWEEN.Tween(start)
            .to(vector, 2000)
            .easing(easing_style)
            .onUpdate(() => {
                camera.position.set(start.x, start.y, start.z)
            })
            .onComplete(() => {
                store.dispatch(setPosition(vector))
                callback()
            })

        animation.start()
    }

    const animateLookAt = (vector, callback) => {
        const { camera: redux_camera } = store.getState()

        const start = { x: redux_camera.lookAt.x, y: redux_camera.lookAt.y, z: redux_camera.lookAt.z }

        const animation = new TWEEN.Tween(start)
            .to(vector, 2300)
            .easing(easing_style)
            .onUpdate(() => {
                camera.lookAt(start.x, start.y, start.z)
            })
            .onComplete(() => {
                store.dispatch(setLookAt(vector))
                callback()
            })

        animation.start()
    }

    useFrame((state, delta) => {
        TWEEN.update()
    })

    const setTarget = (position_target, lookAt_target, callback = () => { }) => {
        let counter = 0;

        const innerCallback = () => {
            counter++
            if (counter === 2)
                callback()
        }

        animatePosition(position_target, innerCallback)
        animateLookAt(lookAt_target,innerCallback)
    }

    return [setTarget]
}

