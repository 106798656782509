import React from 'react'
import { useDispatch } from 'react-redux'
import { setAnimation } from '../../redux/actions/animationAction'

export default function AnimationToggler() {
    const dispatch = useDispatch()
    const handleCameraChange = (val)=>{
        dispatch(setAnimation(val))
    }
    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-end", zIndex: 2 }}>
            <button onClick={()=>handleCameraChange("standing")}>ANIMACJA 1 - STANDING</button>
            <button onClick={()=>handleCameraChange("talking")}>ANIMACJA 2 - TALKING</button>
            <button onClick={()=>handleCameraChange("clapping")}>ANIMACJA 3 - BRAWO</button>
            <button onClick={()=>handleCameraChange("waving")}>ANIMACJA 4 - MACHANIE</button>
        </div>
    )
}
