import React from 'react'

export default function Loading({title}) {
    return (
        <div className="load">
            <div className="load__icon-wrap">
                <svg className="load__icon">
                    <path fill="#ff781f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
                <h1 style={{paddingTop:40, fontWeight:"normal"}}>{title}</h1>
            </div>
        </div>
    )
}
