import React, { useEffect, useState } from 'react'
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { Box3, Vector3, AnimationMixer, LoadingManager } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import host_fbx from './host.fbx'
import { standing_anim, talking_anim, clapping_anim, waving_anim } from './animations'
import { connect, useDispatch } from 'react-redux';
import { modelLoaded } from '../redux/actions/loaderAction';

const animations = {
    "standing": standing_anim,
    "talking": talking_anim,
    "clapping": clapping_anim,
    "waving": waving_anim
}

const HostLoader = ({ currentAnimation, loaded }) => {
    const [host, setHost] = useState()
    const [scale, setScale] = useState(1)

    const { scene } = useThree()

    const [mixer] = useState(() => new AnimationMixer())
    const [currentAction, setAction] = useState()

    const dispatch = useDispatch()

    const runAnimation = (animation) => {
        if (animation) {
            new FBXLoader().load(animation, (obj) => {
                obj.animations.forEach(clip => {
                    const anim = mixer.clipAction(clip, host)
                    anim.reset()

                    currentAction ? anim.crossFadeFrom(currentAction, 0.5, true).play() : anim.play()

                    setAction(anim)
                })
            })
        }
    }

    useEffect(() => {
        if (scene.getObjectByName("host"))
            runAnimation(animations[currentAnimation])
    }, [currentAnimation])


    useEffect(() => {
        setScale(1)
        setHost()

        const manager = new LoadingManager()

        manager.onLoad = () => {
            dispatch(modelLoaded())
        }

        new FBXLoader(manager).load(host_fbx, (obj) => {
            setHost(obj)
        })

    }, [])

    useEffect(() => {
        if (!scene.getObjectByName("host") && host)
            addHost()
    }, [host])

    const addHost = () => {
        const box = new Box3().setFromObject(host)

        const real_height = box.max.y - box.min.y

        const expected_height = 3.5 / 5
        const expected_scale = expected_height / real_height

        if (scale === 1) {
            const three_scale = new Vector3(expected_scale, expected_scale, expected_scale)
            setScale(expected_scale)
            host.scale.copy(three_scale)
        }

        box.max.copy(new Vector3(expected_scale * box.max.x, expected_scale * box.max.y, expected_scale * box.max.z))
        box.min.copy(new Vector3(expected_scale * box.min.x, expected_scale * box.min.y, expected_scale * box.min.z))


        host.position.copy(new Vector3(24.81 / 5, 0.64 / 5, -25 / 5))
        host.lookAt(new Vector3(35 / 5, 0, -53.26 / 5))
        host.name = "host"

        host.updateMatrix()
        runAnimation(animations.standing)

    }


    useFrame((state, delta) => {
        mixer.update(delta)
    })

    return host && loaded ? <primitive object={host} /> : null

}

const mapStateToProps = (props) => {
    return { currentAnimation: props.animation.currentAnimation }
}

export default connect(mapStateToProps)(HostLoader)