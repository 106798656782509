import {
    MODEL_LOADED,
    LAYERS_LOADED
} from '../actions/loaderAction';

const initialState = { counter: 0, layersLoaded: false };

export default function loaderReducer(state = initialState, action) {
    switch (action.type) {
        case MODEL_LOADED:
            return { ...state, counter: ++state.counter };
        case LAYERS_LOADED:
            return { ...state, layersLoaded: true };
        default:
            return state;
    }
}
