import { BackSide } from 'three'
import * as THREE from 'three'
import textures from './textures'
import { useEffect, useState } from 'react'
import { AxesHelper } from 'three';
import { extend, useThree } from '@react-three/fiber'

extend({ AxesHelper })

export default function Background() {
    const [materials, setMaterials] = useState([]);

    const loadTextures = async () => {
        const loader = new THREE.TextureLoader()
        let tmp_materials=[]
        for (let i = 0; i < textures.length; i++) {
            const tmp_texture = await loader.loadAsync(textures[i])
            tmp_materials.push(
                <meshPhongMaterial map={tmp_texture} key={"materials_"+i} attachArray="material" side={BackSide} layers={1} color="white"/>
            )
        }
        setMaterials(tmp_materials)
        return true;
    }

    useEffect(()=>{
        loadTextures()
    }, [])


    return (
        <mesh position={[0, 0.5, 0]} layers={1}>
            <boxBufferGeometry  args={[2, 2, 2]} layers={1}/>
            {materials}
        </mesh>
    );
}
