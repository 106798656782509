export const SET_FILE = 'SET_FILE';
export const SET_ANIMS = "SET_ANIMS"

export function setFile(val) {
    return {
        type: SET_FILE,
        val: val
    };
}

export function setAnims(val) {
    return {
        type: SET_ANIMS,
        val: val
    };
}
