import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { toggleLayer } from '../../redux/actions/layerAction'

const LayerToggler = ({ layer }) => {
    const dispatch = useDispatch()
    const handleLayerChange = (val) => {
        dispatch(toggleLayer(val))
    }
    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-end", zIndex: 2 }}>
            <label style={{ backgroundColor: "wheat" }}>
                <input type="radio" name="layer" value={0} checked={layer===0} onChange={()=>handleLayerChange(0)}/> LAYER 0
            </label>
            <label style={{ backgroundColor: "wheat" }}>
                <input type="radio" name="layer" value={1} checked={layer===1} onChange={()=>handleLayerChange(1)} /> LAYER 1
            </label>

        </div>
    )
}

const mapStateToProps = (state)=>{
    return {layer:state.layer}
}

export default connect(mapStateToProps)(LayerToggler)