import { SET_LOOKAT, SET_POSITION,TOGGLE_CAMERA } from '../actions/cameraAction';
import { Vector3 } from 'three'
const initialState = { lookAt: new Vector3(25/5, 3/5, -27.5/5), position: new Vector3(27.25/5, 3.5/5,  -34/5), currentCamera: undefined };

export default function fileReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOOKAT:
            state.lookAt.set(action.val.x, action.val.y, action.val.z)
            return state;
        case SET_POSITION:
            state.position.set(action.val.x, action.val.y, action.val.z)
            return state;
        case TOGGLE_CAMERA:
            return {...state, currentCamera:action.val}
        default:
            return state;
    }
}
