export const MODEL_LOADED = 'MODEL_LOADED';
export const LAYERS_LOADED = 'LAYERS_LOADED'

export function modelLoaded() {
    return {
        type: MODEL_LOADED,
    };
}

export function layersLoaded() {
    return {
        type: LAYERS_LOADED,
    };
}
