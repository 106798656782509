import React, { useEffect, useState } from 'react'
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { Box3, Vector3, LoadingManager, MeshLambertMaterial, VideoTexture, LinearFilter, RGBFormat, DoubleSide } from 'three'
import { useThree } from '@react-three/fiber'
import studio_fbx from '~studio/studio2.fbx'
import { useDispatch } from 'react-redux';
import { modelLoaded } from '../redux/actions/loaderAction';
const FbxLoaderComponent = ({ loaded }) => {
    const [studio, setStudio] = useState()
    const [scale, setScale] = useState(1)
    const { scene } = useThree()
    const dispatch = useDispatch()
    const [materialDeleted, toggleMaterial] = useState(false)

    useEffect(() => {
        setScale(1)
        setStudio()

        scene.remove(scene.getObjectByName("box_helper"))
        const manager = new LoadingManager()
        manager.onError = (url) => {
            const message = 'Error loading url: ' + url;
            // alert(message);
            console.error('[FBX Viewer] ' + message);
        };

        manager.setURLModifier((url) => {
            let fileName = url.replace("/static/media/", '').split(".")
            fileName = fileName.filter(el => { return el !== "" })

            if (fileName.length === 3)
                fileName.splice(1, 1)

            fileName = fileName.join(".")

            const file = require('~studio/' + fileName)

            return file.default
        });

        manager.onLoad = () => {
            dispatch(modelLoaded())
        }


        new FBXLoader(manager).load(studio_fbx, (obj) => {
            setStudio(obj)
        })

    }, [])


    useEffect(() => {
        if (scene.getObjectByName("studio") && !materialDeleted) {
            const video_el = document.getElementById("video")
            video_el.play()

            const texture = new VideoTexture(video_el)
            texture.minFilter = LinearFilter
            texture.magFilter = LinearFilter;
            texture.format = RGBFormat

            scene.getObjectByName("Mesh718").material.forEach((item, index) => {
                if (item.name === "GH14") {
                    scene.getObjectByName("Mesh718").material[index] = new MeshLambertMaterial({ map: texture, color: 0xffffff, side: DoubleSide })
                    scene.getObjectByName("Mesh718").updateMatrix()
                    return
                }
            })
            scene.getObjectByName("Mesh718").updateMatrix()
            toggleMaterial(true)
        }
    })

    const addStudio = () => {
        const box = new Box3().setFromObject(studio)

        const real_width = box.max.x - box.min.x

        const expected_width = 10
        const expected_scale = expected_width / real_width

        if (scale === 1) {
            const three_scale = new Vector3(expected_scale, expected_scale, expected_scale)
            setScale(expected_scale)
            studio.scale.copy(three_scale)
        }

        box.max.copy(new Vector3(expected_scale * box.max.x, expected_scale * box.max.y, expected_scale * box.max.z))
        box.min.copy(new Vector3(expected_scale * box.min.x, expected_scale * box.min.y, expected_scale * box.min.z))


        studio.position.copy(new Vector3(0, 0, 0))

        studio.matrixAutoUpdate = false
        studio.name = "studio"

        studio.updateMatrix()

    }


    useEffect(() => {
        if (!scene.getObjectByName("studio") && studio)
            addStudio()
    }, [scene, studio])

    return studio && loaded ? <primitive object={studio} /> : null

}


export default React.memo(FbxLoaderComponent)
//export default FbxLoaderComponent