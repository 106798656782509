import { TOGGLE_LAYER } from '../actions/layerAction';

const initialState = null;

export default function layerReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_LAYER:
            return action.layer;
        default:
            return state;
    }
}
