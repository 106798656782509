import io from 'socket.io-client';
import { questionService } from '~services';

const SERVER_URL = process.env.REACT_APP_SERVER_URL

console.log(SERVER_URL)

const socket= io(SERVER_URL)

socket.on("question", (data) => {
  questionService.set(data)
});

export const passSocket  = () =>{
  return socket;
}

