import {
    TOGGLE_FOLLOW
} from '../actions/mouseAction';

const initialState = false;

export default function fileReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_FOLLOW:
            return !state;
        default:
            return state;
    }
}
