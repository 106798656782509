import "bootstrap/dist/css/bootstrap.css";
import './App.css';
import '../../scss/app.scss'
import { connect } from 'react-redux';
import Router from './Router'


function App() {
  return (
    <div style={{ height: "100%", position:"relative" }}>
      <Router />
    </div>
  );
}


export default connect()(App);
