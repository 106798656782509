import React, { useEffect, useState } from 'react'
import { Box3, Vector3, AnimationMixer } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { useDispatch } from 'react-redux';
import { modelLoaded } from '../redux/actions/loaderAction';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const PlayerLoader = ({ file, id, expected_height, position, lookAt, loader, animations, face }) => {
    const [player, setPlayer] = useState()
    const [scale, setScale] = useState(1)

    const { scene } = useThree()

    const [mixer] = useState(() => new AnimationMixer())
    const [faceMixer] = useState(() => new AnimationMixer())

    let panel;

    let currentAction, currentFaceAction;
    let crossFadeControls = [];

    const dispatch = useDispatch()

    const runAnimation = (animation, name) => {
        if (animation) {
            loader.load(animation, (obj) => {
                obj.animations.forEach(clip => {
                    const anim = mixer.clipAction(clip, player)
                    anim.reset()

                    currentAction ? anim.crossFadeFrom(currentAction, 0.5, true).play() : anim.play()

                    currentAction = anim
                })
            })
        }
        else if (name === "None" && currentAction) {
            mixer.stopAllAction()
        }
    }

    const runFaceAnimation = (animation, name) => {
        if (animation) {
            const anim = faceMixer.clipAction(animation, player)
            anim.reset()
            anim.setDuration(1)

            currentFaceAction ? anim.crossFadeFrom(currentFaceAction, 0.5, true).play() : anim.play()

            currentFaceAction = anim
        }
        else if (name === "None" && currentFaceAction) {
            faceMixer.stopAllAction()
        }
    }

    useEffect(() => {
        setScale(1)
        setPlayer()

        loader.load(file, (obj) => {
            setPlayer(obj)
        })

    }, [loader, file])

    useEffect(() => {
        if (!scene.getObjectByName("player_" + id) && player)
            addPlayer()
    }, [player, id, scene])

    const loadFace = (player_obj) => {
        const glbLoader = new GLTFLoader()
        glbLoader.load(face, (obj) => {
            const face_obj = obj.scene

            face_obj.layers.set(1)
            face_obj.traverse(function (child) {
                child.layers.set(1)
            })

            const box = new Box3().setFromObject(face_obj)

            const real_height = box.max.y - box.min.y

            const expected_scale = 40 / real_height

            face_obj.scale.set(expected_scale, expected_scale, expected_scale)
            face_obj.position.set(0, -19, 5.5)

            face_obj.updateMatrix()

            const neck = player_obj.children
                .filter(child => {
                    return child.name === "Armature"
                })[0]
                .children[0].children.filter(bone => {
                    return bone.name.indexOf("Spine") !== -1
                })[0]
                .children[1].children[1].children
                .filter(bone => {
                    return bone.name.indexOf("Neck") !== -1
                })[0]

            const folder = panel.addFolder(`Twarz - player_${id}`);
            const panelSettings = {
                'modify time scale': 1.0
            };

            obj.animations.forEach((item, index) => {
                const name = item.name;

                panelSettings[name] = function () {
                    runFaceAnimation(item)
                };

                crossFadeControls.push(folder.add(panelSettings, name));
            })

            folder.open()

            neck.add(face_obj)
        })
    }

    const addPlayer = async () => {
        const box = new Box3().setFromObject(player)

        const real_height = box.max.y - box.min.y

        const expected_scale = expected_height / real_height

        if (scale === 1) {
            const three_scale = new Vector3(expected_scale, expected_scale, expected_scale)
            setScale(expected_scale)
            player.scale.copy(three_scale)
        }

        player.traverse(function (child) {
            child.layers.set(1)
        })

        player.position.set(position.x, position.y, position.z)
        player.lookAt(lookAt.x, lookAt.y, lookAt.z)
        player.name = `player_${id}`

        createPanel()

        if (face) {
            loadFace(player)
        }

        player.updateMatrix()

        dispatch(modelLoaded())
    }

    const createPanel = () => {
        panel = new GUI({ width: 150 })

        const baseNames = ['None', ...Object.keys(animations)];
        const folder1 = panel.addFolder(`Animacje - player_${id}`);
        const panelSettings = {
            'modify time scale': 1.0
        };

        for (let i = 0, l = baseNames.length; i !== l; ++i) {

            const name = baseNames[i];
            const settings = animations[name];


            panelSettings[name] = function () {
                runAnimation(settings, name)
            };

            crossFadeControls.push(folder1.add(panelSettings, name));

        }

        folder1.open()

        crossFadeControls.forEach(function (control) {
            control.classList1 = control.domElement.parentElement.parentElement.classList;
            control.classList2 = control.domElement.previousElementSibling.classList;
        });
    }

    useFrame((state, delta) => {
        mixer.update(delta)
        faceMixer.update(delta)
    })


    return player ? <primitive object={player} layers={1} /> : null

}


export default React.memo(PlayerLoader)