import model from './nohead.fbx'
import standing from './animations/standing.fbx'
import victory from './animations/victory.fbx'
import defeated from './animations/defeated.fbx'

export default model;

export const player2_standing = standing;
export const player2_victory = victory;
export const player2_defeated = defeated;
