import { useThree } from "@react-three/fiber";
import React, { useRef } from "react";
import * as THREE from 'three';

const Sun = ({ position, rotation, basicMaterial, target }) => {
    const { scene } = useThree();
    const lightRef = useRef()
    let blob = new THREE.CylinderBufferGeometry(0.3, 0.03, 6, 5, 2, true);
    const material = basicMaterial.clone()
    if (lightRef.current) {
        const spotLight = new THREE.SpotLight()
        spotLight.position.copy(position)
        spotLight.position.y = 3.2
        spotLight.color = lightRef.current.material.uniforms.lightColor.value
        spotLight.castShadow=false;
        spotLight.intensity = 0.5
        spotLight.visible=false
        scene.add(spotLight)
        spotLight.target.position.set(target)
        spotLight.matrixAutoUpdate = false
        scene.add(spotLight.target)

        lightRef.current.matrixAutoUpdate = false

    }

    material.uniforms.spotPosition.value = position

    return (
        <object3D position={position} rotation={rotation}>
            <mesh
                ref={lightRef}
                position={[0, 3.2, 0]}
                geometry={blob}
                material={material}
            />
        </object3D>
    );
}

const Light = (props) => {
    return (
        <Sun {...props} />
    );
}

export default React.memo(Light)


