import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleCamera } from '../../redux/actions/cameraAction'

export default function CameraToggler() {
    const dispatch = useDispatch()
    const handleCameraChange = (val)=>{
        dispatch(toggleCamera(val))
    }
    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-end", zIndex: 2 }}>
            <button onClick={()=>handleCameraChange(0)}>KAMERA 1 - STUDIO</button>
            <button onClick={()=>handleCameraChange(1)}>KAMERA 2 - PROWADZĄCY NA WPROST</button>
            <button onClick={()=>handleCameraChange(2)}>KAMERA 3 - PROWADZĄCY Z TYŁU</button>
            <button onClick={()=>handleCameraChange(3)}>KAMERA 4 - PROWADZĄCY Z GÓRY</button>
            <button onClick={()=>handleCameraChange(4)}>KAMERA 5 - TELEBIM</button>
        </div>
    )
}
