import React, { useState } from 'react'
import { Container, Row, Card, CardBody } from 'reactstrap'
import { alertService } from '~services'
const Login = ({ history }) => {
    const [code, setCode] = useState("")
    const handleGameEnter = () => {
        if (!!code) {
            history.push(`/${code}`)
        }
        else
            alertService.error("Podaj kod gry")
    }

    return (
        <Container fluid className="h-100 ">
            <Row className="h-100">
                <Card className="grey-background">
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <form className="card-container" onSubmit={e => e.preventDefault()}>
                            <label htmlFor="gamecode">
                                <h2>Podaj kod gry</h2>
                            </label>

                            <input
                                name="gamecode"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value)
                                }}
                            />
                            
                            <button
                                type="submit"
                                onClick={handleGameEnter}>
                                Wejdź do gry
                            </button>
                        </form>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    )
}

export default Login;