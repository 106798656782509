import React from 'react'

export default function Answer({content}) {
    return (
        <div className="question-answer">
        <div className="background"></div>
            <p>{content}</p>
        </div>
    )
}
